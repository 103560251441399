@font-face {
    font-family: "Montserrat";
    src: url("../../Fonts/Montserrat-SemiBold.ttf");
}
@keyframes delay-overflow {
    from { overflow: hidden; }
  }

.customComponents__filterPanel {
    all: initial;
    width: 100%;
    height: auto;

    &__filterList {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        outline: none;
        min-height: 41px;

        & > span {
            font-family: Montserrat;
            cursor: pointer;
            border-radius: 5px;
            height: 100%;
            padding: 10px;
            height: 31px;
            display: inline-block;
            transition: background-color 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #5F6470;
            overflow: hidden;
            white-space: nowrap;
            padding: 0;
            background-color: #EEEEF4;
            &:hover {
                background-color: #caccf5;
            }
            & span:nth-child(2) {
                margin-left: 5px;
            }
        }
        &__addFilter {
            font-family: Montserrat;
            color: #75757c;
            width: auto !important;
            display: flex;
            align-items: center;
            height: 31px;
            padding: 10px 5px;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            span {
                font-family: Montserrat;
            }
            &:hover {
                background-color: #dededf !important;
            }
        }
    }
    &__addFilterWrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;
        max-height: 0;
        overflow: hidden;
        padding: 0 0;
        transition: max-height 0.3s ease, overflow 0.5s ease, padding 0.3s ease;
        flex-wrap: wrap;
        &--active {
            max-height: 100px;
            padding: 20px 0px;
            overflow: initial;
            animation: 1s delay-overflow;
            @media screen and (max-width: 700px) {
                max-height: 150px;
                min-height: 150px;
            }
        }
        div{
            margin-right: 30px;
        }
    }
}
 
.customComponents__filterPanel--dark {
    .customComponents__filterPanel__filterList {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        background-color: transparent;

        & > span {
            background-color: #2B2D3E;
            color: white;
            &:hover {
                background-color: #3e4064;
            }
        }
    }
    .customComponents__filterPanel__filterList {
        &__addFilter {
            color: white;

            &:hover {
                background-color: #3e4064 !important;
            }
        }
        & > span {
            filter: brightness(150%);
        }
    }
}