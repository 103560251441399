.component__dueListModal{
    background: rgba(0, 0, 0, 0.459);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0%;
    overflow: hidden;
    animation: animateDueListModal 0.5s linear;

    @keyframes animateDueListModal{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }        
    }
    &__container{
        background: white;
        width: 846px;
        height: 846px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        z-index: 1;
        border-radius: 10px;
        animation: animateDueModalContainer 0.5s linear;
        @keyframes animateDueModalContainer{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }

        &__head{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 30px;
            h1{
                color: #272737;
                font-family: 'Roboto';
                font-size: 30px;
            }
            img{
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }

        &__body{
            padding: 30px;
            overflow-y: scroll;
            height: 80%;

            &::-webkit-scrollbar {
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #272737;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgb(0, 163, 255);
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgb(0, 163, 255);
            }

            &__item{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                background: #272737;
                padding: 10px;

                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    p{
                        color: white;
                        font-family: 'Roboto';
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 862px){
    .component__dueListModal{
        &__container{
            width: 95%;
            &__body{
                padding: 10px;
            }
        }
    }
}

@media screen and (max-width: 498px){
    .component__dueListModal__container__body__item div p{
        font-size: 14px;
    }
}