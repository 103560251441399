.component__questionModal {
    position: static;
    z-index: 1;
    background: white;
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgb(150, 150, 151);
    margin-top: 100px;
    margin-bottom: 100px;
    overflow: hidden;
    animation: animateQuestionModal 1s linear;

    @keyframes animateQuestionModal {
        0%{
            max-height: 0;
        }
        100%{
            max-height: 1000px;
        }
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 38px;
        color: #272737;
        text-align: center;
    }

    div{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        width: 100%;
    }

    &__chooseTypeModal{
        flex-direction: column;
        animation: animateLang 1s linear;
        overflow: hidden;
        @keyframes animateLang{
            0%{
                max-height: 0px;
            }
            100%{
                max-height: 1000px;
            }
        }
        p{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 38px;
            color: rgb(0, 163, 255);
            text-align: center;
            cursor: pointer;
        }
        div{
            flex-direction: column;
            animation: animateType 1s linear;

            @keyframes animateType{
                0%{
                    max-height: 0px;
                }
                100%{
                    max-height: 1000px;
                }
            }
        }
    }
}