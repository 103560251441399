#edit-article-msg{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #272737;
    background: rgb(103, 229, 100);
    padding: 3px;
    text-align: center;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    animation: animateMsg 0.5s linear;
    margin-bottom: 20px;

    @keyframes animateMsg{
        0%{
            max-height: 0;
        }
        100%{
            max-height: 1000px;
        }
    }
}

#error-article-msg{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: white;
    background: rgb(255, 0, 0);
    padding: 3px;
    text-align: center;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    animation: animateMsg 0.5s linear;
    margin-bottom: 20px;

    @keyframes animateMsg{
        0%{
            max-height: 0;
        }
        100%{
            max-height: 1000px;
        }
    }
}

.component__editArticle{
    padding: 30px;
    width: 90%;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    margin-left: auto;
    margin-right: auto;
    animation: animateModal 1s linear;
    overflow: hidden;

    @keyframes animateModal {
        0% {
            max-height: 0;
        }

        100% {
            max-height: 1000px;
        }
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #73717C;
        margin-top: 20px;
    }

    strong {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
    }

    img {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;

        input {
            outline: none;
            border: 1px solid #272737;
            height: 100%;
            padding: 1px;
            animation: animateInput 0.5s linear;

            @keyframes animateInput {
                0% {
                    max-width: 0;
                }

                100% {
                    max-width: 1000px;
                }
            }
        }
    }

    &__controles{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}