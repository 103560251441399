.component__editClient{
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
    animation: animateThis 0.5s linear;

    #edit-client-msg{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
        background: rgb(103, 229, 100);
        padding: 3px;
        text-align: center;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        animation: animateMsg 0.5s linear;

        @keyframes animateMsg{
            0%{
                max-height: 0;
            }
            100%{
                max-height: 1000px;
            }
        }
    }

    &__box{
        border-radius: 10px;
        width: 846px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        position: relative;
        animation: animateSettings 1s linear;

        @keyframes animateSettings {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
        
        &__contactInformations{
            padding: 30px;
            width: 90%;
            height: fit-content;
            background: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
            animation: animateSettings 1s linear;
            display: block;
            margin-left: auto;
            margin-right: auto;

            h1 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #272737;
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #73717C;
                margin-top: 20px;
            }

            strong {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #272737;
            }

            img {
                width: 14px;
                height: 14px;
                cursor: pointer;
            }

            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 16px;

                input {
                    outline: none;
                    border: 1px solid #272737;
                    height: 100%;
                    padding: 1px;
                    animation: animateInput 0.5s linear;

                    @keyframes animateInput {
                        0% {
                            max-width: 0;
                        }

                        100% {
                            max-width: 1000px;
                        }
                    }
                }
            }
            &__controles{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1182px){
    .component__editClient__box{
        width: 100%;
    }
}

@media screen and (max-width: 567px){
    .component__editClient__box{
        padding: 5px;
    }
    .component__editClient__box__contactInformations{
        width: 100%;
    }
    .component__customComponent__dropbox{
        margin-top: 10px;
    }
    .customComponents__table__filterHead__addFilterWrap--active{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100% !important;
        padding-left: 0;
        padding: 5px;
    }
    .customComponents__input__textbox:not(:placeholder-shown) + .customComponents__input__placeholder{
        top: 0 !important;
    }
}

@media screen and (max-width: 433px){

}