.component__postbacks {
    width: 846px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-height: 1200px;
    animation: animateNavigate 1s linear;

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
        font-family: 'Roboto';

        .customComponents__buttonSmall.addNewPostback {
            width: 170px;
            height: 40px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
        }
    }

    #msg{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: white;
        padding: 3px;
        text-align: center;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        animation: aniMsg 0.5s linear;
        margin-bottom: 20px;
    
        @keyframes aniMsg{
            0%{
                max-height: 0;
            }
            100%{
                max-height: 1000px;
            }
        }
    }

    &__addNewPostbackModal {
        padding: 30px;
        width: 90%;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 5px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
        margin-left: auto;
        margin-right: auto;
        animation: animateArticleModal 1s linear;
        overflow: hidden;

        @keyframes animateArticleModal {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }

        #flags-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-bottom: 20px;

            div {
                position: relative;
                display: flex;
                justify-content: baseline;
                align-items: center;
                margin-top: 20px;
                cursor: pointer;

                p {
                    margin-top: 0;
                    margin-left: 10px;

                    &:hover+#flag-desc {
                        display: block;
                        color: red;
                    }
                }

                #flag-desc {
                    background: white;
                    z-index: 2;
                    top: -300%;
                    border-radius: 5px;
                    display: none;
                    position: absolute;
                    padding: 5px;
                    box-shadow: 0 0 4px gray;
                }
            }
        }

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #272737;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #73717C;
            margin-top: 20px;
        }

        strong {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #272737;
        }

        img {
            width: 14px;
            height: 14px;
            cursor: pointer;
        }

        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 16px;

            input {
                outline: none;
                border: 1px solid #272737;
                height: 100%;
                padding: 1px;
                animation: animateInput 0.5s linear;

                @keyframes animateInput {
                    0% {
                        max-width: 0;
                    }

                    100% {
                        max-width: 1000px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 876px){
    .component__postbacks{
        width: 95%;
    }
    .component__postbacks__addNewPostbackModal #flags-grid{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 648px){
    .component__postbacks__addNewPostbackModal #flags-grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 448px){
    .component__postbacks__addNewPostbackModal #flags-grid{
        grid-template-columns: 1fr;
    }
}