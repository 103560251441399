@keyframes animateThis {
    0% {
        max-height: 0;
    }

    100% {
        max-height: 1000px;
    }
}

#error-article-msg{
    display: none;
}

.component__clients {
    background: #F5F5F5;
    padding-bottom: 20px;
    animation: animateNavigate 1s linear;

    &__container {
        width: 846px;
        height: fit-content;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        animation: animateThis 0.5s linear;

        .close{
            animation: closeModal 0.5s linear;

            @keyframes closeModal{
                0%{
                    opacity: 1;
                }
                100%{
                    opacity: 0;
                }
            }
        }

        &__createNewClient {
            width: 100%;
            height: fit-content;
            margin-bottom: 20px;
            animation: animateThis 0.5s linear;

            .createNewClient__errormsg{
                color: white;
                background: red;
                width: 100%;
                border-radius: 0px 0px 5px 5px;
                text-align: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                animation: animateMsg 0.5s linear;
                @keyframes animateMsg{
                    0%{
                        max-height: 0;
                    }
                    100%{
                        max-height: 1000px;
                    }
                }
            }
            .createNewClient__msg {
                color: white;
                background: rgb(103, 229, 100);
                width: 100%;
                border-radius: 0px 0px 5px 5px;
                text-align: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                animation: animateMsg 0.5s linear;
                @keyframes animateMsg{
                    0%{
                        max-height: 0;
                    }
                    100%{
                        max-height: 1000px;
                    }
                }
            }

            &__box {
                border-radius: 10px;
                width: 846px;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 30px;
                position: relative;
                animation: animateSettings 0.5s linear;

                @keyframes animateSettings {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__contactInformations {
                    padding: 30px;
                    width: 90%;
                    height: fit-content;
                    background: #FFFFFF;
                    border-radius: 5px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                    animation: animateSettings 1s linear;

                    h1 {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #272737;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #73717C;
                        margin-top: 20px;
                    }

                    strong {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #272737;
                    }

                    img {
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                    }

                    span {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        margin-top: 16px;

                        input {
                            outline: none;
                            border: 1px solid #272737;
                            height: 100%;
                            padding: 1px;
                            animation: animateInput 0.5s linear;

                            @keyframes animateInput {
                                0% {
                                    max-width: 0;
                                }

                                100% {
                                    max-width: 1000px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__dropdown {
                width: 192px;
                height: 49px;
                border-radius: 5px;
                background-color: white;
                z-index: 2;
            }

            &__dropdown-header {
                padding: 15px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #272737;
            }

            &__dropdown-body {
                padding: 5px;
                border-top: 1px solid #E5E8EC;
                display: none;
            }

            &__dropdown-body.open {
                display: block;
                position: absolute;
                width: fit-content;
                margin-top: 10px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                padding: 20px;
                animation: animateDropdown 0.5s linear;
                overflow: hidden;

                @keyframes animateDropdown {
                    0% {
                        max-height: 0;
                    }

                    100% {
                        max-height: 1000px;
                    }
                }
            }

            &__dropdown-item {
                padding: 10px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #272737;
                transition: 400ms;
            }

            &__dropdown-item:hover {
                cursor: pointer;
                color: #00A3FF;
            }

            &__dropdown-item-dot {
                opacity: 0;
                color: #91A5BE;
                transition: all .2s ease-in-out;
            }

            &__dropdown-item-dot.selected {
                opacity: 1;
            }

            .icon {
                font-size: 13px;
                color: #91A5BE;
                transform: rotate(0deg);
                transition: all .2s ease-in-out;
            }

            .icon.open {
                transform: rotate(90deg);
            }

            h1 {
                width: 50%;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 38px;
                color: #272737;
            }

            .new-client-btn {
                width: 144px;
                height: 49px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
            }
        }

        &__stats {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 50px;
            padding-left: 30px;
            padding-right: 30px;

            &__item {
                background: #D3E7FF;
                text-align: center;
                padding: 15px;

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 42px;
                    text-align: center;
                    color: #1511F9;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #717171;
                }
            }
        }

        &__recentlyActive {
            h1 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #272737;
            }

            &__clientBox {
                margin-top: 16px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
            }
        }
    }
}

@media screen and (max-width: 1182px) {
    .component__clients {
        &__container {
            width: 700px;

            &__recentlyActive {
                &__clientBox {
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
    .component__clients__container__createNewClient__box{
        width: 100%;
    }
}

@media screen and (max-width: 743px){
    .component__clientProfile:nth-child(4){
        display: none;
    }
    .component__clients__container__recentlyActive__clientBox{
        grid-template-columns: 1fr 1fr 1fr;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .component__clients__container{
        width: 95%;
    }

    .component__clients__container__recentlyActive__clientBox{
        grid-template-columns: 1fr 1fr;
    }
    .component__clientProfile:nth-child(3){
        display: none;
    }
    .component__clientProfile__head{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        div{
            text-align: center;
        }
        #pimg{
            margin-right: 0 !important;
        }
    }
    .component__clientProfile__body span{
        flex-direction: column !important;
        img{
            margin-right: 0 !important;
        }
    }
    .component__clients__container__recentlyActive__clientBox{
        width: 95%;
    }
    #table-company-info{
        align-items: center;
    }
    .component__clients__container__recentlyActive__clientBox{
        gap: 10px;
    }

    .component__clients__container__createNewClient__box__contactInformations{
        width: 100%;
    }
}

@media screen and (max-width: 600px){
    #table-company-info{
        align-items: flex-end;
    }
}

@media screen and (max-width: 434px){
    .component__clients__container__createNewClient__box{
        padding: 5px;
    }
}