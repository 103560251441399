.component__apiTokens{
    width: 846px;
    height: fit-content;
    min-height: 1200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    animation: animateNavigate 1s linear;

    @keyframes animateNavigate{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    &__head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        h1{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #272737;
        }
        .addNewToken{
            width: 170px;
            height: 40px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
        }
    }

    #msg{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: white;
        padding: 3px;
        text-align: center;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        animation: aniMsg 0.5s linear;
        margin-bottom: 20px;
    
        @keyframes aniMsg{
            0%{
                max-height: 0;
            }
            100%{
                max-height: 1000px;
            }
        }
    }

    &__addNewTokenModal{
        padding: 30px;
        width: 90%;
        height: fit-content;
        background: #FFFFFF;
        border-radius: 5px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
        margin-left: auto;
        margin-right: auto;
        animation: animateArticleModal 1s linear;
        overflow: hidden;

        @keyframes animateArticleModal {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }

        #flags-grid{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-bottom: 20px;
            div{
                position: relative;
                display: flex;
                justify-content: baseline;
                align-items: center;
                margin-top: 20px;
                cursor: pointer;
                p{
                    margin-top: 0;
                    margin-left: 10px;
                    &:hover + #flag-desc{
                        display: block;
                        color: red;
                    }
                }

                #flag-desc{
                    background: white;
                    z-index: 2;
                    top: -300%;
                    border-radius: 5px;
                    display: none;
                    position: absolute;
                    padding: 5px;
                    box-shadow: 0 0 4px gray;
                }
            }
        }

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #272737;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #73717C;
            margin-top: 20px;
        }

        strong {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #272737;
        }

        img {
            width: 14px;
            height: 14px;
            cursor: pointer;
        }

        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 16px;

            input {
                outline: none;
                border: 1px solid #272737;
                height: 100%;
                padding: 1px;
                animation: animateInput 0.5s linear;

                @keyframes animateInput {
                    0% {
                        max-width: 0;
                    }

                    100% {
                        max-width: 1000px;
                    }
                }
            }
        }
    }
}

#table-flags{
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    align-items: center;
    width: 100%;
    #table-flags-content{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 5px;
        width: 100%;
        p{
            width: 150px;
            font-size: 12px;
            background: rgb(80, 137, 207);
            color: white;
            padding: 3px;
            border-radius: 5px;
        }
    }
}

#table-token-info{
    font-family: 'Roboto';
    font-size: 12px;
    text-align: center;
}

#table-token-fhead{
    p{
        font-family: 'Roboto';
        font-size: 12px;
    }
}

#token{
    font-size: 12px;
    word-break: break-all;
}

@media screen and (max-width: 879px){
    .component__apiTokens{
        width: 95%;
    }

    #table-flags{
        #table-flags-content{
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}

@media screen and (max-width: 700px){
    .component__apiTokens__addNewTokenModal #flags-grid{
        grid-template-columns: 1fr 1fr;
    }
    #table-flags{
        width: 100%;
        #table-flags-content{
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media screen and (max-width: 550px){
    #table-flags{
        width: 100%;
        #table-flags-content{
            grid-template-columns: 1fr 1fr;
        }
    }
}