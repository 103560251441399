.component__confirmationTravel{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    width: 1350px;
    margin-left: -27%;
    background: white;
    padding: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    position: relative;
    margin-bottom: 50px;
    animation: animateOrder .5s linear;

    @keyframes animateOrder{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .saveConBtn{
        position: absolute;
        right: 0;
        border-radius: 0 5px 0 5px;
    }

    #msg{
        position: absolute;
        width: 100%;
        text-align: center;
        background: red;
        color: white;
        font-size: 14pt;
        padding: 3px;
        font-family: 'Roboto';
        height: 50px;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        animation: animateMsg .5s linear;

        @keyframes animateMsg{
            0%{
                max-height: 0px;
            }
            100%{
                max-height: 1000px;
            }
        }
    }

    input{
        font-family: 'Roboto';
        font-size: 16px;
    }

    &__left{
        &__item{
            font-family: 'Roboto';
            border-bottom: 1px solid black;
            padding-bottom: 10px;
            span{
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-top: 20px;
                input{
                    outline: none;
                    border: none;
                    border-bottom: 1px solid rgb(0, 163, 255);
                    margin-left: 5px;
                    margin-right: 5px;
                }
                #long{
                    width: 70%;
                }
            }
            #item-foo{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 40%;
                margin-left: auto;
                margin-right: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    small{
                        font-size: 10px;
                    }
                }
            }
        }
    }
    &__right{
        font-family: 'Roboto';
        h1{
            font-size: 20px;
            text-align: center;
        }
        span{
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;
            margin-top: 20px;
            input{
                outline: none;
                border: none;
                border-bottom: 1px solid rgb(0, 163, 255);
                margin-left: 5px;
            }
            #medium{
                width: 27%;
            }
        }
        &__table{
            display: grid;
            grid-template-columns: 70% 30%;
            gap: 0;
            #tspan{
                margin-top: 0;
                width: 100%;
                border: 1px solid black;
                height: 25px;
                padding-left: 10px;
                font-weight: 700;
            }
            table{
                div{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 70% 30%;
                    input{
                        width: 100%;
                        outline: none;
                        border: 1px solid black;
                        height: 25px;
                        padding-left: 10px;
                    }
                }
            }
            #right{
                border: 1px solid black;
                height: 96%;
                margin-top: 2px;
                p{
                    font-size: 14px;
                    margin-left: 5px;
                    margin-top: 5px;
                }
                textarea{
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 75%;
                    padding: 10px;
                    font-family: 'Roboto';
                }
            }
        }
        #sum{
            border: 1px solid black;
            margin-top: 0;
            padding-left: 10px;
            display: grid;
            grid-template-columns: 69% 31%;
            padding-top: 2px;
            width: 99.6%;
            margin-right: 0;
            margin-left: auto;
            input{
                border-left: 1px solid black;
                border-bottom: none;
                padding-left: 5px;
            }
        }
    }
}

@media screen and (max-width: 1804px){
    .component__confirmationTravel{
        width: 1200px;
        margin-left: -20%;
    }
}

@media screen and (max-width: 1535px){
    .component__confirmationTravel{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 684px){
    .component__confirmationTravel{
        padding: 20px;
    }
    .component__confirmationTravel__left__item span{
        flex-direction: column;
        margin-top: 10px;
    }
    .component__confirmationTravel__left__item p {
        margin-top: 10px;
    }
    .component__confirmationTravel__right__table{
        grid-template-columns: 1fr;
    }
    .component__confirmationTravel__right span{
        flex-direction: column;
    }
    .component__confirmationTravel__left__item #item-foo{
        width: 100%;
    }
}