.component__travelOrder{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    width: 1350px;
    margin-left: -27%;
    background: white;
    padding: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    position: relative;
    animation: animateOrder .5s linear;

    @keyframes animateOrder{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    #msg{
        position: absolute;
        width: 100%;
        text-align: center;
        color: white;
        font-size: 14pt;
        padding: 3px;
        font-family: 'Roboto';
        height: 50px;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        animation: animateMsg .5s linear;

        @keyframes animateMsg{
            0%{
                max-height: 0px;
            }
            100%{
                max-height: 1000px;
            }
        }
    }

    input{
        margin-left: 5px;
        margin-right: 5px;
    }

    &__left{
        text-align: left;
        position: relative;
        h1{
            font-family: 'Roboto';
            font-size: 24px;
        }
        h2{
            font-family: 'Roboto';
            font-size: 17px;
        }
        p{
            position: absolute;
            bottom: 0;
            right: 50px;
            font-family: 'Roboto';
        }
    }
    &__right{

        #clearBtn{
            width: 14px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
        }

        .saveOrderBtn{
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 5px 0 5px !important;
        }

        span{
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: baseline;
            font-size: 16px;
            font-family: 'Roboto';
            margin-top: 10px;
            input{
                outline: none;
                border: none;
                border-bottom: 1px solid rgb(0, 163, 255);
                font-size: 16px;
            }
            #long-input{
                width: 80%;
            }
            #super-long-input{
                width: 100%;
            }
            #small-input{
                width: 50px;
            }
            #date-input{
                width: 150px;
                margin-left: 10px;
                margin-right: 10px;
            }

            #money-input{
                margin-left: 5px;
                width: 100px;
            }
            #medium{
                width: 150px;
            }
        }
        h1{
            font-family: 'Roboto';
            font-size: 22px;
            margin-top: 30px;
            text-align: center;
        }
        p{
            margin-top: 20px;
            font-family: 'Roboto';
            font-size: 16px;
        }
        #foo{
            margin-top: 100px;
            justify-content: space-evenly;
        }
    }

    &__currencyModal{
        position: absolute;
        background: rgb(0, 163, 255);
        padding: 5px;
        border-radius: 5px 0 5px 0px;
        z-index: 1;
        div{
            animation: animateCur 1s linear;
            overflow: hidden;

            @keyframes animateCur{
                0%{
                    max-height: 0px;
                }
                100%{
                    max-height: 1000px;
                }
            }
        }
        strong{
            font-family: 'Roboto';
            color: white;
            cursor: pointer;
        }
        p{
            font-family: 'Roboto';
            color: white;
            margin-top: 5px;
        }
    }

    &__workersModal{
        position: absolute;
        background: rgba(0, 0, 0, 0.288);
        width: 100%;
        height: 100%;
        z-index: 1;
        animation: animateWorkersModal .5s linear;

        @keyframes animateWorkersModal{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }

        #box{
            background: white;
            width: 80%;
            padding: 20px;
            margin-right: auto;
            margin-left: auto;
            margin-top: 25%;
            font-family: 'Roboto';
            border-radius: 5px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
            span{
                width: 300px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-bottom: 10px;
                border-bottom: 1px solid black;
            }
            #list{
                overflow-y: scroll;
                padding-right: 5px;
                padding-top: 7px;
                span{
                    transition: 400ms;
                    &:hover{
                        color: rgb(0, 163, 255);
                    }
                }
                &::-webkit-scrollbar {
                    width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #272737;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(0, 163, 255);
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(0, 163, 255);
                }
            }
        }
    }

    &__vehiclesModal{
        position: absolute;
        background: rgba(0, 0, 0, 0.288);
        width: 100%;
        height: 100%;
        z-index: 1;
        animation: animateVehiclesModal .5s linear;

        @keyframes animateVehiclesModal{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }

        #box{
            background: white;
            width: 80%;
            padding: 20px;
            margin-right: auto;
            margin-left: auto;
            margin-top: 25%;
            font-family: 'Roboto';
            border-radius: 5px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);

            span{
                width: 300px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                margin-bottom: 10px;
                border-bottom: 1px solid black;
            }
            #list{
                overflow-y: scroll;
                padding-right: 5px;
                padding-top: 7px;
                span{
                    transition: 400ms;
                    &:hover{
                        color: rgb(0, 163, 255);
                    }
                }
                &::-webkit-scrollbar {
                    width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #272737;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(0, 163, 255);
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(0, 163, 255);
                }
            }
        }
    }
}

@media screen and (max-width: 1804px){
    .component__travelOrder{
        width: 1200px;
        margin-left: -20%;
    }
}

@media screen and (max-width: 1502px){
    .component__travelOrder{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 678px){
    .component__travelOrder{
        padding: 20px;
        padding-top: 50px;
    }
    .component__travelOrder__right span{
        flex-direction: column;
    }
    .component__travelOrder__left p{
        bottom: -30px;
    }
}