.route__landingPage {
    display: grid;
    grid-template-columns: 20% 80%;
    width: 100%;
    height: 100%;

    &__pdfDownloadStarted{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.479);
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: animateSpinner .5s linear;

        @keyframes animateSpinner{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
    }

    &__menu {
        background: #272737;
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        position: relative;

        &__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            #hamburger {
                display: none;
            }

            #settings-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            #scale-logo {
                width: 166px;
                height: 59px;
            }

            &__settingsModal {
                width: 271px;
                height: fit-content;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                position: absolute;
                right: -15rem;
                top: 62px;
                padding: 20px;
                animation: animateSettingsModal 0.3s linear;
                z-index: 1;

                @keyframes animateSettingsModal {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__changePassword{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    animation: animatePass .5s linear;

                    @keyframes animatePass{
                        0%{
                            max-height: 0px;
                        }
                        100%{
                            max-height: 1000px;
                        }
                    }
                    input{
                        margin-top: 10px;
                        padding: 5px;
                        outline: none;
                    }
                    #changePassBtn{
                        margin-top: 10px;
                        background: #272737;
                        padding: 5px;
                        color: #F5F5F5;
                        border-radius: 5px;
                        width: 100px;
                    }
                }

                h2 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #272737;
                }

                button {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #3F73FF;
                    background: transparent;
                    border: none;
                    margin-top: 8px;
                    cursor: pointer;
                }

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;
                    margin-top: 16px;

                    img {
                        margin-right: 16px;
                        width: 20px;
                        height: 20px;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #272737;
                        transition: 400ms;
                        cursor: pointer;

                        &:hover {
                            color: #3F73FF;
                        }
                    }
                }
            }
        }

        #settings-icon2 {
            display: none;
        }

        #logged-user {
            margin-top: 22px;
            color: white;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
        }

        &__navItem {
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;
            margin-top: 35px;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
                margin-right: 22px;
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
            }
        }

        #nav-item1 {
            margin-top: 43px !important;
        }
    }

    &__body {
        background: #F5F5F5;
        width: 100%;
    }
}

@media screen and (max-width: 1500px) {
    .route__landingPage {
        grid-template-columns: 25% 75%;
    }
}

@media screen and (max-width: 1166px) {
    .route__landingPage {
        grid-template-columns: 30% 70%;
    }
}

@media screen and (max-width: 1161px) {
    @keyframes animateMenu{
        0%{
            max-height: 0;
        }
        100%{
            max-height: 1000px;
        }
    }
    .route__landingPage {
        grid-template-columns: 1fr;

        &__menu {
            padding-bottom: 17px;
            width: 100%;
            padding-top: 17px;
            padding-left: 20px;
            &__head {
                padding-bottom: 0px;

                #hamburger {
                    display: block;
                }

                #settings-icon {
                    display: none;
                }
            }

            #menu-wrapper {
                display: none;
                animation: animateMenu 1s linear;
                overflow: hidden;
            }

            #settings-icon2 {
                display: block;
                display: flex;

                img {
                    width: 20px;
                    height: 20px;
                }
            }

            &__mobileSettings {
                margin-top: 30px;
                margin-left: 23px;
                animation: animateMenu 1s linear;
                overflow: hidden;

                h2 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #FFFFFF;
                }

                button {
                    background: transparent;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #3F73FF;
                    border: none;
                    cursor: pointer;
                    margin-top: 8px;
                }

                span {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: baseline;
                    margin-top: 18px;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 16px;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #FFFFFF
                    }
                }
            }
        }
    }
}