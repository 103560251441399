.customComponents__spinner {
    all: initial;
    width: 64px;
    height: 64px;

    &__path {
        stroke: #00A3FF;
        stroke-linecap: round;
        transform-origin: center;
        animation: spinner__dash 1.5s ease-in-out infinite, spinner__rotate 2s linear infinite;
        transition: stroke 0.3s ease;
    }
}

@keyframes spinner__rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner__dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}