.component__dashboard {
    width: 100%;
    background: #F5F5F5;
    padding-bottom: 20px;
    min-height: 1000px;
    position: relative;
    animation: animateNavigate 1s linear;

    &__container {
        margin-top: 50px;
        width: 846px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        &__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 38px;
            }

            .newInvoice {
                width: 154px;
                height: 49px;
                left: 1425px;
                top: 50px;
                border-radius: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
            }

            &__dropdown {
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 10px;
                background-color: transparent;
                position: relative;
            }
    
            &__dropdown-header {
                padding: 15px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #272737;
            }
    
            &__dropdown-body {
                padding: 5px;
                border-top: 1px solid #E5E8EC;
                display: none;
                z-index: 2;
            }
    
            &__dropdown-body.open {
                display: block;
                position: absolute;
                width: fit-content;
                margin-top: 10px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                padding: 20px;
                animation: animateDropdown 0.5s linear;
                overflow: hidden;
    
                @keyframes animateDropdown {
                    0% {
                        max-height: 0;
                    }
    
                    100% {
                        max-height: 1000px;
                    }
                }
            }
    
            &__dropdown-item {
                padding: 10px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #272737;
                transition: 400ms;
            }
    
            &__dropdown-item:hover {
                cursor: pointer;
                color: #00A3FF;
            }
    
            &__dropdown-item-dot {
                opacity: 0;
                color: #91A5BE;
                transition: all .2s ease-in-out;
            }
    
            &__dropdown-item-dot.selected {
                opacity: 1;
            }
    
            .icon {
                font-size: 13px;
                color: #91A5BE;
                transform: rotate(0deg);
                transition: all .2s ease-in-out;
            }
    
            .icon.open {
                transform: rotate(90deg);
            }
        }

        &__stats {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 50px;
            margin-top: 30px;

            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 223px;
                height: 85px;
                background: #D3E7FF;
                margin-left: auto;
                margin-right: auto;

                &:nth-child(1){
                    cursor: pointer;
                    position: relative;
                    #notification{
                        position: absolute;
                        right: 3px;
                        top: 3px;
                        animation: blinkingDot 1.5s infinite;
                        @keyframes blinkingDot{
                            0%{
                                opacity: 0;
                            }
                            100%{
                                opacity: 1;
                            }
                        }
                    }
                }

                h1 {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 42px;
                    text-align: center;
                    color: #1511F9;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #717171;
                }
            }
        }

        &__invoicesList {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 20px;
            width: 100%;
            margin-top: 20px;
        }

        &__allInvoicesList {
            &__head {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;

                h1 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 28px;
                    color: #272737;
                }

                input {
                    width: 201px;
                    height: 49px;
                    background: #FFFFFF;
                    padding-left: 57px;
                    background: url("../../../public/images/searchIcon.png") no-repeat left;
                    background-size: 24px;
                    background-position: 20px;
                    border: none;
                    outline: none;
                    background-color: white;

                    &::placeholder {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #272737;
                    }
                }
            }
            &__body{
                position: relative;
            }
        }
    }
}

/* TABLE CSS */

#table-company-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #717171;
    }
}

#table-company-due {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    place-items: center;
    cursor: pointer;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
        margin-bottom: 10px;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #717171;
        margin-bottom: 10px;
    }

    .createInvButton{
        font-family: 'Roboto';
        font-weight: 900;
    }
}

.customComponents__table__head p {
    color: black !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #272737 !important;
}

.customComponents__table__head {
    place-items: center !important;
    align-items: center !important;
}

.customComponents__table{
    border-left: 0 !important;
    margin-top: 35px !important;
}

.customComponents__table__filterHead{
    margin-top: 35px !important;
}

@media screen and (max-width: 1166px){
    .component__dashboard{
        &__container{
            width: 700px;
            &__stats{
                &__item{
                    width: 180px;
                    min-width: none;
                    h1{
                        font-size: 22px;
                    }
                }
            }
            &__invoicesList{
                grid-template-columns: 1fr 1fr;
                place-items: center;
            }
        }
    }
}

@media screen and (max-width: 714px){
    .component__dashboard__container__invoicesList{
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .component__dashborad__container__allInvoicesList__body{
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .component__dashboard__container__allInvoicesList__head{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .component__dashboard__container__head{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .component__dashboard__container__stats{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .component__dashboard__container{
        width: 100%;
    }
    .component__dashboard__container__stats__item{
        width: 100%;
        height: 110px;
        h1{
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 600px){
    #table-company-due{
        display: flex;
        align-items: flex-end;
    }
}

@media screen and (max-width: 550px){
    #table-amount{
        justify-content: flex-end;
        align-items: flex-end;
    }
    .component__dashboard__container__head__dropdown-body.open{
        z-index: 1;
    }
}

@media screen and (max-width: 540px){
    .component__dashboard__container__stats{
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        &__item{
            &:last-child{
                display: none;
            }
        }
    }
    .component__dashboard__container__invoicesList{
        gap: 14px;
        .component__newInvoiceBlock{
            &:nth-child(3), &:nth-child(4){
                display: none;
            }
        }
    }
    /*.customComponents__table__data{
        display: flex !important;
        flex-direction: column !important;
    }*/
    .component__dashborad__container__allInvoicesList__body{
        width: 90% !important;
    }
    .component__dashboard__container__invoicesList{
        width: 90%;
        .component__newInvoiceBlock__body{
            height: 200px;
        }
        .component__newInvoiceBlock__status{
            margin-top: 0;
        }

    }
}