.component__createNewInvoice {
    height: fit-content;
    background: #F5F5F5;
    width: 1000px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    animation: fadeinout 0.5s linear forwards;

    #mobile-settings-icon {
        display: none;
    }

    @keyframes fadeinout {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .msg {
        display: none;
        color: #272737;
        background: #67E564;
        font-family: 'Roboto';
        font-size: 16px;
        text-align: center;
        width: 100%;
        margin-top: 10px;
        font-weight: 600;
        padding: 5px;
        border-radius: 0px 0px 15px 15px;
        animation: animateMessage 2s linear;

        @keyframes animateMessage {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }
    }

    &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        h1 {
            margin-right: 40%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #272737;
        }

        p {
            cursor: pointer;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #272737;
        }

    }

    &__template {
        display: grid;
        grid-template-columns: 700px 300px;
        gap: 20px;
        margin-left: 100px;

        &__file {
            width: 700px;
            background: white;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            height: fit-content;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            padding: 50px;

            #person-responsible {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                color: #272737;
                margin-top: 6px;
            }

            &__head {
                display: grid;
                grid-template-columns: 50% 50%;
                width: 100%;

                div {
                    h4 {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                        margin-top: 6px;
                    }
                }

                img {
                    display: block;
                    margin-left: auto;
                    margin-right: 0;
                    width: 166px;
                    height: 59px;
                    cursor: pointer;
                }
            }

            &__client {
                display: grid;
                grid-template-columns: 50% 50%;
                margin-top: 43px;

                &__left {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: baseline;

                    #invoice-customer {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                        padding-bottom: 2px;
                        border-bottom: 1px solid gray;
                        margin-bottom: 2px;
                    }

                    strong {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                        margin-top: 5px;
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                    }

                    .addClient-btn {
                        width: 144px;
                        height: 35px;
                        background: rgba(63, 115, 255, 0.07);
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #272737;
                        transition: 400ms;

                        &:hover {
                            background: rgba(63, 115, 255, 0.07) !important;
                        }
                    }

                    &__addClientModal {
                        position: absolute;
                        width: fit-content;
                        z-index: 1;
                        animation: animateInvoiceModal 1s linear;
                        display: flex;
                        flex-direction: column;
                        height: 200px;
                        width: 100%;
                        padding: 5px;
                        overflow: hidden;

                        &__nav {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 12px;
                                height: 12px;
                                cursor: pointer;
                                margin-left: 5px;
                                display: block;
                                margin-top: 5px;
                            }

                            input {
                                outline: none;
                                margin-top: 5px;
                                animation: animateInvoiceModal 1s linear;
                                width: 100%;
                            }
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: #272737;
                            margin-top: 0.5rem;
                            cursor: pointer;
                            padding: 3px;

                            &:hover {
                                background: rgba(63, 115, 255, 0.07);
                            }
                        }

                        background: white;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                        padding: 5px;

                        &__list {
                            overflow-y: scroll;
                            margin-top: 3px;

                            &::-webkit-scrollbar {
                                width: 5px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #272737;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: rgb(0, 163, 255);
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: rgb(0, 163, 255);
                            }
                        }
                    }


                }

                &__right {
                    position: relative;

                    span {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }

                    &__dueDateModal {
                        width: 173px;
                        height: fit-content;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                        border-radius: 2px;
                        z-index: 1;
                        position: absolute;
                        animation: animateInvoiceModal 1s linear;

                        &__head {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding-left: 15px;
                            padding-right: 15px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                            background: rgba(63, 115, 255, 0.07);

                            img {
                                width: 12px;
                                height: 12px;
                                cursor: pointer;
                            }

                            h5 {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: left;
                                color: #0D99FF;
                            }
                        }

                        &__body {
                            padding: 10px;

                            span {
                                display: flex;
                                flex-direction: row;
                                justify-content: left;
                                align-items: center;
                                margin-bottom: 10px;

                                input {
                                    background: #FFFFFF;
                                    border: 1px solid #73717C;
                                    margin-right: 10px;
                                    width: 16px;
                                    height: 16px;
                                }

                                p {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: right;
                                    color: #272737;
                                }
                            }

                            &__daysInput {
                                display: flex;
                                flex-direction: column;
                                justify-content: baseline;
                                align-items: center;
                                margin-top: 9px;
                                margin-bottom: 9px;
                                margin-left: 32px;

                                p {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: left;
                                    color: #272737;
                                    margin-bottom: 8px;
                                }

                                input {
                                    width: 24px;
                                    height: 24px;
                                    background: rgba(63, 115, 255, 0.07);
                                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                    border-radius: 2px;
                                    border: none;
                                    outline: none;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: center;
                                    color: #272737;
                                }
                            }
                        }
                    }
                }
            }

            &__description {
                position: relative;

                &__headers {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    place-items: top;
                    min-height: 50px;

                    #service-description {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                    }

                    #service-price {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                    }

                    #service-mpc {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                    }

                    #service-quantity {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                    }

                    #service-totalWithoutPDV {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                    }

                    #service-pdv {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                    }

                    div {
                        text-align: center;

                        input {
                            width: 228px;
                            height: 28px;
                            background: #FFFFFF;
                            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                            border-radius: 2px;
                            outline: none;
                            border: none;
                            padding: 3px;
                            margin-top: 3px;
                            position: absolute;
                            left: 0;
                        }

                        &:nth-child(1) {
                            text-align: left;
                            width: auto;
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            color: #272737;
                        }

                        strong {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 14px;
                            color: #272737;
                        }
                    }
                }

                .addNewLine-btn {
                    width: 100%;
                    height: 35px;
                    background: rgba(63, 115, 255, 0.07);
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #272737;
                    transition: 400ms;

                    &:hover {
                        background: rgba(63, 115, 255, 0.07) !important;
                    }
                }

                &__servicesModal {
                    position: absolute;
                    width: 100%;
                    z-index: 1;
                    height: fit-content;
                    top: 3rem;
                    background: white;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                    border-radius: 2px;
                    padding: 5px;
                    animation: animateInvoiceModal 1s linear;
                    cursor: pointer;

                    &__addCustomServiceModal{
                        position: absolute;
                        background: white;
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                        padding: 10px;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        left: 20%;
                        right: 20%;
                        top: 2.5%;
                        width: fit-content;
                        animation: animateCustomArticleModal .5s linear;

                        @keyframes animateCustomArticleModal{
                            0%{
                                opacity: 0;
                            }
                            100%{
                                opacity: 1;
                            }
                        }

                        .customServiceBtn{
                            margin-top: 20px;
                            height: fit-content;
                            width: 70px;
                            padding: 5px;
                            font-size: 15px;
                            font-family: 'Roboto';
                            font-weight: 700;
                        }

                        img{
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                        }

                        p{
                            margin-top: 20px;
                            margin-bottom: 20px;
                            font-family: 'Roboto';
                            cursor: default;
                        }

                        input{
                            outline: none;
                            width: 300px;
                            height: 2rem;
                            margin-top: 10px;
                            padding-left: 10px;
                            border-radius: 5px;
                            border: 1px solid black;
                        }
                    }

                    img {
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                    }

                    #services-header{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding-bottom: 5px;
                    }

                    #services-scrollable{
                        overflow-y: scroll;
                        max-height: 300px;
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
    
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #272737;
                        }
    
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: rgb(0, 163, 255);
                        }
    
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: rgb(0, 163, 255);
                        }
                        div {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            width: 100%;
    
                            p {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                color: #272737;
                                margin-top: 0.5rem;
                                cursor: pointer;
                                width: 140px;
    
                                &:nth-child(1) {
                                    width: 50px;
                                }
                            }
    
                            transition: 400ms;
                            padding: 3px;
    
                            &:hover {
                                background: rgba(63, 115, 255, 0.07);
                            }
                        }
                    }
                }

                &__quantityModal {
                    position: absolute;
                    width: 50%;
                    background: white;
                    z-index: 1;
                    top: 0;
                    left: 25%;
                    padding: 20px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                    flex-direction: column;
                    animation: animateQuantity 0.2s linear;

                    @keyframes animateQuantity {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    &__warning{
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: white;
                        width: 100%;
                        height: 100%;
                        font-family: 'Roboto';
                        animation: animateWarningQ 0.5s linear;

                        @keyframes animateWarningQ{
                            0%{
                                opacity: 0;
                            }
                            100%{
                                opacity: 1;
                            }
                        }
                        strong{
                            cursor: pointer;
                            margin-top: 5px;
                        }
                        p{
                            text-align: center;
                            font-size: 14px;
                            border-bottom: 1px solid black;
                        }
                    }

                    &__enterValue {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        h1 {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 14px;
                            color: #272737;
                        }

                        input {
                            width: 50px;
                            height: 24px;
                            background: rgba(63, 115, 255, 0.07);
                            border-radius: 2px;
                            border: none;
                            outline: none;
                            font-family: 'Roboto';
                            font-size: 12px;
                            text-align: center;
                            color: #272737;
                        }
                    }

                    &__button {
                        margin-top: 15px;
                        width: 50px;
                        height: 30px;
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }

            &__total {
                display: grid;
                grid-template-columns: 50% 50%;

                &__table {
                    width: 100%;
                    position: relative;

                    &__rabatModal {
                        position: absolute;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                        border-radius: 2px;
                        width: fit-content;
                        height: 126px;
                        animation: animateInvoiceModal 1s linear;
                        overflow: hidden;

                        h5 {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: left;
                            color: #0D99FF;
                            background: rgba(63, 115, 255, 0.07);
                            border-radius: 2px 2px 0px 0px;
                            padding-left: 18px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                        }

                        &__body {
                            padding: 10px;

                            div {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                align-items: center;
                                justify-content: space-evenly;
                                margin-bottom: 19px;

                                input {
                                    width: 50px;
                                    height: 24px;
                                    background: rgba(63, 115, 255, 0.07);
                                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                    border-radius: 2px;
                                    border: none;
                                    outline: none;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: center;
                                    color: #272737;
                                }


                                p {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: right;
                                    color: #272737;
                                }

                                .close-btn {
                                    color: black !important;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #272737;
                                }

                                .add-rabat-btn {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #FFFFFF;
                                    width: 96px;
                                    height: 26px;
                                }
                            }
                        }
                    }

                    &__paidModal {
                        position: absolute;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                        border-radius: 2px;
                        width: fit-content;
                        height: 126px;
                        animation: animateInvoiceModal 1s linear;
                        overflow: hidden;

                        h5 {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: left;
                            color: #0D99FF;
                            background: rgba(63, 115, 255, 0.07);
                            border-radius: 2px 2px 0px 0px;
                            padding-left: 18px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                        }

                        &__body {
                            padding: 10px;

                            div {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 19px;

                                input {
                                    width: 100px;
                                    height: 24px;
                                    margin-right: auto;
                                    margin-left: auto;
                                    background: rgba(63, 115, 255, 0.07);
                                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                    border-radius: 2px;
                                    border: none;
                                    outline: none;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: center;
                                    color: #272737;
                                }

                                .close-btn {
                                    color: black !important;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #272737;
                                }

                                .add-paid-btn {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #FFFFFF;
                                    width: 96px;
                                    height: 26px;
                                }
                            }
                        }
                    }

                    &__avansModal {
                        position: absolute;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                        border-radius: 2px;
                        width: fit-content;
                        height: 126px;
                        animation: animateInvoiceModal 1s linear;
                        overflow: hidden;

                        h5 {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: left;
                            color: #0D99FF;
                            background: rgba(63, 115, 255, 0.07);
                            border-radius: 2px 2px 0px 0px;
                            padding-left: 18px;
                            padding-top: 8px;
                            padding-bottom: 8px;
                        }

                        &__body {
                            padding: 10px;

                            div {
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 19px;

                                input {
                                    width: 100px;
                                    height: 24px;
                                    margin-right: auto;
                                    margin-left: auto;
                                    background: rgba(63, 115, 255, 0.07);
                                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                    border-radius: 2px;
                                    border: none;
                                    outline: none;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-align: center;
                                    color: #272737;
                                }

                                .close-btn {
                                    color: black !important;
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #272737;
                                }

                                .add-avans-btn {
                                    font-family: 'Roboto';
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 14px;
                                    color: #FFFFFF;
                                    width: 96px;
                                    height: 26px;
                                }
                            }
                        }
                    }

                    table {
                        width: 100%;
                        text-align: center;

                        td {
                            cursor: pointer;
                            &:nth-child(2) {
                                text-align: right;
                            }

                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: center;
                            color: #272737;
                        }
                    }
                }
            }

            &__amountDue {
                display: grid;
                grid-template-columns: 50% 25% 25%;

                div {
                    p {
                        text-align: center;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 15px;
                        text-align: center;
                        color: #00A3FF;
                    }

                    h3 {
                        text-align: right;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: right;
                        color: #272737;
                    }
                }
            }

            &__remark {
                margin-top: 15px;
                position: relative;
                min-height: 150px;
                height: fit-content;
                margin-bottom: 20px;

                #remark-in {
                    img {
                        width: 10px;
                        height: 10px;
                        margin-right: 3px;
                        cursor: pointer;
                    }
                }

                .addRemarkButton {
                    width: 100%;
                    height: 35px;
                    background: rgb(241, 241, 241);
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: #272737;
                    transition: 400ms;
                    margin-bottom: 10px;
                    cursor: pointer;

                    &:hover {
                        background: rgba(63, 115, 255, 0.07) !important;
                    }
                }

                p {

                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: #272737;
                    margin-top: 5px;
                }

                &__remarkModal {
                    z-index: 1;
                    position: absolute;
                    background: white;
                    width: 100%;
                    padding: 5px;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    height: 100px;
                    top: 40px;
                    overflow: hidden;
                    animation: animateRemarkModal 1s linear;

                    @keyframes animateRemarkModal {
                        0% {
                            max-width: 0;
                        }

                        100% {
                            max-width: 1000px;
                        }
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        color: #272737;
                        margin-top: 10px;
                    }

                    &__head {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        input {
                            width: 97%;
                            outline: none;
                            padding: 3px;
                        }

                        img {
                            width: 14px;
                            height: 14px;
                            cursor: pointer;
                        }
                    }

                    &__list {
                        overflow-y: scroll;
                        margin-top: 3px;
                        height: 100%;
                        padding-bottom: 30px;

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #272737;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: rgb(0, 163, 255);
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: rgb(0, 163, 255);
                        }

                        p {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 14px;
                            color: #272737;
                        }

                        span {
                            display: grid;
                            grid-template-columns: 3% 97%;
                            place-items: baseline;
                            margin-top: 10px;
                            cursor: pointer;

                            img {
                                width: 10px;
                                height: 10px;
                                margin-right: 5px;
                            }

                            p {
                                margin-top: 0;
                            }

                            transition: 400ms;
                            padding: 3px;

                            &:hover {
                                background: rgba(63, 115, 255, 0.07);
                            }
                        }
                    }
                }
            }

            &__notes {
                margin-top: 0px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                place-items: top;

                h1 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 15px;
                    color: #00A3FF;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                }

                div {

                    &:nth-child(2) {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    &:nth-child(3) {
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }
        }

        &__options {
            display: block;

            &__settings {
                width: 306px;
                height: fit-content;
                background: #FFFFFF;
                border-radius: 5px;
                margin-top: 30px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                padding: 30px;
                position: relative;

                strong {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #272737;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #73717C;
                }

                #online-payments,
                #customize-style,
                #make-recurring {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 80% 20%;
                    margin-bottom: 20px;

                    div {
                        display: flex;
                        flex-direction: column;

                        p {
                            margin-top: 16px;
                        }
                    }

                    span {
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;

                        img {
                            display: block;
                            margin-right: 0;
                            margin-left: auto;
                        }
                    }
                }

                &__logosModal{
                    position: absolute;
                    width: 100%;
                    background: white;
                    top: 0;
                    left: 0;
                    height: 100%;
                    padding: 20px;
                    justify-content: baseline;
                    align-items: baseline;
                    flex-direction: column;
                    animation: animateLogosModal .5s linear;
                    overflow: hidden;
                    @keyframes animateLogosModal{
                        0%{
                            max-width: 0;
                        }
                        100%{
                            max-width: 1000px;
                        }
                    }

                    img{
                        width: 14px;
                        cursor: pointer;
                    }

                    #logos-title{
                        border-bottom: 1px solid gray;
                        margin-top: 10px;
                    }

                    input {
                        color: transparent;
                        display: block;
                        margin-left: 20%;
                        height: 80px;
                    }
    
                    input::-webkit-file-upload-button {
                        visibility: hidden;
                    }
    
                    input::before {
                        content: "▲ Upload New Logo";
                        color: #fff;
                        display: inline-block;
                        background: rgb(0, 163, 255);
                        padding: 10px 22px;
                        outline: none;
                        white-space: nowrap;
                        //-webkit-user-select: none;
                        cursor: pointer;
                        font-weight: 400;
                        border-radius: 2px;
                        outline: none;
                        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
                    }
    
                    input:focus {
                        outline: none !important;
    
                    }
    
                    input:active::before {
                        transform: scale(.9) translate(0px, 2px);
                        box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
    
                    }

                    &__list{
                        display: flex;
                        flex-direction: column;
                        justify-content: baseline;
                        align-items: baseline;
                        overflow-y: scroll;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10px;

                        &::-webkit-scrollbar {
                            width: 5px;
                        }
            
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #272737;
                        }
            
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: rgb(0, 163, 255)
                        }
            
                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: rgb(0, 163, 255)
                        }

                        span{
                            display: flex;
                            flex-direction: row;
                            justify-content: baseline;
                            align-items: center;
                            width: 100%;
                            height: 100px;
                            margin-top: 10px;
                            padding: 5px;
                            #trash{
                                width: 14px;
                                height: 14px;
                                margin-right: 5px;
                            }
                            #logo{
                                width: 220px;
                                height: 100%;
                            }
                        }
                    }
                }

                &__languageModal{
                    position: absolute;
                    background: white;
                    width: 150px;
                    top: 60%;
                    padding: 10px;
                    right: 5px;
                    box-shadow: 0 0 4px gray;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: baseline;
                    overflow: hidden;
                    animation: animateLangModal 1s linear;

                    @keyframes animateLangModal{
                        0%{
                            max-height: 0px;
                        }
                        100%{
                            max-height: 1000px;
                        }
                    }

                    p{
                        &:first-child{
                            margin-top: 0;
                        }
                        margin-top: 10px;
                        color: #272737;
                        cursor: pointer;
                        transition: 400ms;
                        &:hover{
                            color: #00A3FF;
                        }
                    }
                }
            }

            &__userSettings {
                width: 306px;
                height: 414px;
                background: #FFFFFF;
                border-radius: 5px;
                margin-top: 30px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                padding: 30px;
                position: relative;

                strong {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #272737;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #717171;
                }

                &__typeModal {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    background: #FFFFFF;
                    font-size: 16px;
                    padding: 10px;
                    z-index: 1;
                    bottom: 5%;
                    right: 0;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    height: fit-content;
                    animation: animateCurrencyModal 1s linear;
                    overflow: hidden;

                    @keyframes animateCurrencyModal {
                        0% {
                            max-height: 0;
                        }

                        100% {
                            max-height: 1000px;
                        }
                    }

                    p {
                        margin-top: 5px;
                        cursor: pointer;
                        color: #272737;
                        transition: 400ms;
                        &:hover{
                            color: #00A3FF;
                        }
                    }
                }

                &__currencyModal {
                    flex-direction: column;
                    //justify-content: center;
                    align-items: center;
                    position: absolute;
                    background: #FFFFFF;
                    font-size: 16px;
                    padding: 10px;
                    z-index: 1;
                    top: 50%;
                    right: 0;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    max-height: 200px;
                    animation: animateCurrencyModal 1s linear;

                    @keyframes animateCurrencyModal {
                        0% {
                            max-height: 0;
                        }

                        100% {
                            max-height: 1000px;
                        }
                    }

                    input {
                        outline: none;
                        padding: 2px;
                    }

                    p {
                        margin-top: 5px;
                        cursor: pointer;
                        color: #272737;
                        transition: 400ms;

                        &:hover {
                            color: #0D99FF;
                        }
                    }

                    div {
                        overflow: hidden;
                        overflow-y: scroll;
                        width: 100%;
                        margin-top: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #272737;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: rgb(0, 163, 255);
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: rgb(0, 163, 255);
                        }
                    }
                }

                &__pdvModal {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    background: #FFFFFF;
                    font-size: 16px;
                    padding: 13px;
                    z-index: 1;
                    top: 45%;
                    right: 0%;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    height: fit-content;
                    width: 100px;
                    animation: animateCurrencyModal 1s linear;

                    @keyframes animateCurrencyModal {
                        0% {
                            max-height: 0;
                        }

                        100% {
                            max-height: 1000px;
                        }
                    }

                    input{
                        width: 50px;
                        outline: none;
                        padding: 2px;
                    }

                    .setPDVbutton{
                        margin-top: 10px;
                        width: 50px;
                        height: 30px;
                    }
                }

                #profile {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;

                    #profile-picture {
                        margin-right: 12px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }

                    p {
                        margin-top: 10px;
                    }
                }

                #send-reminder,
                #change-last-fees,
                #currency,
                #invoice-attachment {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 80% 20%;
                    margin-bottom: 20px;

                    div {
                        display: flex;
                        flex-direction: column;

                        p {
                            margin-top: 16px;
                        }
                    }

                    span {
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;

                        img {
                            display: block;
                            margin-right: 0;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}

@keyframes animateInvoiceModal {
    0% {
        max-width: 0;
    }

    100% {
        max-width: 1000px;
    }
}

@media screen and (max-width: 1647px) {
    .component__createNewInvoice {
        &__template {
            margin-left: 0;
            grid-template-columns: 650px 350px;
            &__file{
                width: 650px;
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .component__createNewInvoice {
        width: 100%;
        #mobile-settings-icon {
            display: block;
            cursor: pointer;
        }

        .component__createNewInvoice__template {
            grid-template-columns: 1fr;
            position: relative;
        }

        &__template__options {
            display: none;
            position: absolute;
            right: 0;
            top: -3rem;
            animation: animateModalOpen 0.5s linear;
            overflow: hidden;

            @keyframes animateModalOpen {
                0% {
                    max-height: 0;
                }

                100% {
                    max-height: 1000px;
                }
            }
        }
        &__head{
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: 871px) {
    .component__createNewInvoice {
        width: 95%;
    }

    .component__createNewInvoice__head {
        width: 100%;
    }

    .component__createNewInvoice__head h1 {
        margin-right: 20%;
    }
}

@media screen and (max-width: 660px) {
    .component__createNewInvoice__template__file {
        width: 100%;
    }

    .component__createNewInvoice__head h1 {
        margin-right: 0%;
    }
    .component__createNewInvoice__template__file__description__servicesModal__addCustomServiceModal{
        left: 15%;
    }
}

@media screen and (max-width: 560px){
    .component__createNewInvoice__template__file__description__servicesModal #services-scrollable div p{
        width: fit-content;
    }
    .component__createNewInvoice__template__file{
        padding: 20px;
    }
    .component__createNewInvoice__template__file__description__quantityModal{
        width: 100%;
        left: 0;
    }
    .component__createNewInvoice__template__file__description__quantityModal__enterValue input{
        width: 150px;
    }
    .component__createNewInvoice__template__file__description__quantityModal__button{
        width: 150px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 533px) {
    .component__createNewInvoice__head {
        display: grid;
        grid-template-columns: 50% repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 3px;
        grid-row-gap: 3px;
    }

    #head-item1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    #head-item2 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .head-item3 {
        grid-area: 2 / 2 / 3 / 3;
    }

    .head-item4 {
        grid-area: 2 / 3 / 3 / 4;
    }

    .head-item5 {
        grid-area: 2 / 4 / 3 / 5;
        display: block;
        margin-left: auto;
        margin-right: 0;
    }

    .component__createNewInvoice__template__file__description__headers div:nth-child(1) {
        width: 120px;
    }

    .component__createNewInvoice__template__file__description__headers div strong {
        font-size: 10px;
    }

    .component__createNewInvoice__template__file__total {
        grid-template-columns: 30% 70%;
    }
}

@media screen and (max-width: 508px){
    .component__createNewInvoice__template__file__remark__remarkModal__list span{
        grid-template-columns: 5% 95%;
    }
}

@media screen and (max-width: 473px) {
    .component__createNewInvoice__template__file {
        width: 100%;
        padding: 30px;
    }

    .component__createNewInvoice__head {
        width: 100%;
    }

    .component__createNewInvoice__template__file__description__headers div:nth-child(1) {
        width: 120px;
    }

    .component__createNewInvoice__template__file__description__headers div strong {
        font-size: 10px;
    }
    .component__createNewInvoice__template__file__description__servicesModal__addCustomServiceModal{
        left: 7%;
    }
}

@media screen and (max-width: 468px) {
    .component__createNewInvoice__template__file {
        width: 95%;
        padding: 10px;
    }

    .component__createNewInvoice__head {
        grid-template-columns: 40% repeat(3, 1fr);
        width: 100%;
    }

    .component__createNewInvoice__head h1 {
        font-size: 25px;
    }

    .component__createNewInvoice__template__file__client {
        grid-template-columns: 40% 60%;
    }

    .component__createNewInvoice__template__file__client__left .addClient-btn {
        width: 110px;
    }
}

@media screen and (max-width: 435px) {
    .component__createNewInvoice__template__file__description__headers div:nth-child(1) {
        width: 80px;
    }
}

@media screen and (max-width: 402px) {
    .component__createNewInvoice__head {
        width: 100%;
    }

    .component__createNewInvoice__template__file__client__left .addClient-btn {
        width: 100px;
    }
    .component__createNewInvoice__template__file__description__servicesModal__addCustomServiceModal{
        input{
            width: 250px;
        }
    }
}