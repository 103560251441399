@font-face {
    font-family: "Montserrat";
    src: url("../../Fonts/Montserrat-SemiBold.ttf");
}

.customComponents__input {
    all: initial;
    width: auto;
    height: auto;
    min-width: 200px;
    min-height: 45px;
    color: black;
    position: relative;
    font-family: "Montserrat";

    &__textbox {
        display: block;
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0 5px;
        border-bottom: 1px solid #00A3FF;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 1px);
        font-size: 16px;
        &:not(:placeholder-shown)::placeholder {
            padding-bottom: 20px;
        }
        &::placeholder {
            opacity: 0;
        }
    }
    &__placeholder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 1px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 5px;
        background-color: transparent;

        pointer-events: none;
        cursor: default;
        transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
    }
    &__textbox:not(:placeholder-shown) + &__placeholder {
        top: -25px !important;
        font-size: 14px;
        color: #00A3FF;
    }
}
.customComponents__input.customComponents__input--dark {
    color: white;

    & .customComponents__input__textbox {
        color: white;
    }
}