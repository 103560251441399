.component__travelOrders {
    width: 846px;
    min-height: 1100px;
    height: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: animateNavigate 1s linear;

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #272737;
        }

        .addNewWarrant {
            margin-left: 10px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            width: 200px;
            padding: 4px;
        }
    }

    &__view {
        width: 1350px;
        height: fit-content;
        background: white;
        position: relative;
        padding: 50px;
        border-radius: 10px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        margin-left: -27%;
        animation: animateTravel .5s linear;
        display: grid;
        grid-template-columns: 50% 50%;
        font-family: 'Roboto';

        @keyframes animateTravel {
            0% {
                opacity: 0;
            }
    
            100% {
                opacity: 1;
            }
        }

        &__left{
            position: relative;
            h1{
                font-size: 22px;
                text-align: left;
            }
            h2{
                font-size: 18px;
                text-align: left;
            }
            p{
                position: absolute;
                bottom: 0;
                right: 100px;
            }
        }

        &__right{
            h1{
                margin-top: 20px;
                font-size: 20px;
                text-align: center;
                font-weight: 400;
            }
            p{
                margin-top: 20px;
                span{
                    border-bottom: 1px solid black;
                }
            }

            #foo{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                margin-top: 50px;
            }
        }
    }
}

@media screen and (max-width: 900px){
    .component__travelOrders{
        width: 95%;
    }
    .component__travelOrders__head h1{
        font-size: 25px;
    }
    .component__travelOrders__head .addNewWarrant{
        width: 150px;
    }
}

@media screen and (max-width: 1804px){
    .component__travelOrders__view{
        width: 1200px;
        margin-left: -20%;
    }
}

@media screen and (max-width: 1551px){
    .component__travelOrders__view{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 682px){
    .component__travelOrders__head{
        flex-direction: column;
        div{
            margin-top: 10px;
        }
    }
    .component__travelOrders__view__left{
        display: flex;
        flex-direction: column;
        p{
            position: initial;
            margin-top: 20px;
        }
    }
    .component__travelOrders__view{
        padding: 20px;
    }
}