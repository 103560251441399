.component__clientProfile {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    strong {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #717171;
    }

    #blank {
        width: 100%;
        height: 10px;
    }

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: baseline;
        align-items: center;
        padding: 16px;
        height: fit-content;

        #pimg {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #2351CF;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 12px;
            background: rgba(63, 115, 255, 0.2);
        }

        div {
            strong {
                font-size: 14px;
            }
            p {
                margin-top: 10px;
                font-size: 13px;
            }
        }
    }

    &__body {
        padding: 16px;

        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: baseline;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #717171;
            }
        }
    }
}