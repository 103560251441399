.route__login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__box{
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;
        padding: 20px;
        margin-left: 50px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        width: fit-content;
        height: fit-content;

        &__msg{
            color: red;
            margin-bottom: 5px;
        }
    
        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 38px;
            color: #272737;
        }
    
        input {
            width: 300px;
            height: 50px;
            margin-bottom: 20px;
            border: none;
            padding: 5px;
            font-size: 14px;
            outline: none;
            //border-radius: 5px;
            border-bottom: 1px solid gray;
    
            &::placeholder {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .route__login{
        &__box{
            width: 350px;
            margin-left: 0;
        }
    }
}