.component__newInvoiceBlock {
    display: flex;
    flex-direction: column;
    width: 196px;
    height: 248px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    &__body{
        padding: 20px;
        padding-top: 30px;
    }

    #hline{
        margin-top: 30px;
    }

    #id {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #717171;
    }

    #company-name {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #272737;
        margin-top: 17px;
    }

    #date {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #717171;
        margin-top: 12px;
    }

    #amount {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        color: #272737;
        margin-top: 16px;
    }

    &__status {
        margin-top: 14px;
        width: 100%;
        background: rgba(254, 105, 105, 0.27);
        height: 44px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #272737;

        }
    }
}

@media screen and (max-width: 1166px){
    .component__newInvoiceBlock{
        width: 100%;
        height: fit-content;
    }
}