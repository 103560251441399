.component__team {
    background: #F5F5F5;
    animation: animateNavigate 1s linear;

    &__addUserModal{
        position: absolute;
        top: 0;
        width: 80%;
        height: 100%;
        background: rgba(0, 0, 0, 0.336);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        animation: animateUsr .5s linear;

        @keyframes animateUsr {
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
        &__box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: fit-content;
            background: white;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
            #msg{
                color: #67E564;
                font-family: 'Roboto';
                font-weight: 700;
            }
            h1{
                font-family: 'Roboto';
                font-size: 22px;
            }
            input{
                width: 300px;
                outline: none;
                margin-top: 10px;
                height: 2rem;
                padding: 5px;
                font-family: 'Roboto';
                font-size: 11pt;
                border: none;
                border-bottom: 1px solid black;
            }
            div{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
            }
        }
    }

    &__container {
        width: 846px;
        height: 1100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;

        &__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 38px;
                color: #272737;
            }

            .invite-btn {
                width: 101px;
                height: 49px;
                background: #67E564;
                border-radius: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
            }
        }

        &__allMembers {
            margin-top: 30px;
            &__head {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                h2 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 28px;
                    color: #272737;
                }

                &__searchbar {
                    width: fit-content;
                    height: fit-content;
                    left: 1378px;
                    top: 144px;
                    outline: none;
                    border: none;
                    background-color: #FFFFFF;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        margin-left: 24px;
                    }

                    input {
                        outline: none;
                        border: none;
                        width: 201px;
                        height: 49px;
                    }
                }
            }

            &__table {
                width: 100%;
                text-align: left;

                th {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 21px;
                    color: #272737;
                    border-bottom: 1px solid #939393;
                    padding-bottom: 14px;
                }

                td {
                    border-bottom: 1px solid #939393;
                    padding-top: 14px;
                    padding-bottom: 14px;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #272737;
                    input{
                        width: 24px;
                        height: 24px;
                    }
                    #deleteUserBtn{
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        font-size: 14pt;
                        padding: 5px;
                        cursor: pointer;
                        background: #272737;
                        color: white;
                        border: none;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1485px){
    .component__team__addUserModal{
        width: 75%;
    }
}

@media screen and (max-width: 1188px){
    .component__team{
        &__container{
            width: 700px;
        }
    }
}

@media screen and (max-width: 1144px){
    .component__team__addUserModal{
        width: 100%;
    }
}

@media screen and (max-width: 749px){
    .component__team{
        &__container{
            width: 95%;
        }
    }
}

@media screen and (max-width: 450px){
    .component__team{
        &__container{
            width: 95%;
        }
    }
    .component__team__container__allMembers__head__searchbar{
        display: none;
    }
}