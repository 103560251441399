.route__chooseBusiness {
    display: grid;
    grid-template-columns: 20% 80%;
    height: 100%;
    width: 100%;

    &__chooseModal {
        width: 543px;
        height: 440px;
        left: 688px;
        top: 255px;
        background: #F1F1F1;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 60px;
        padding-bottom: 60px;

        .addNewBusinessButton{
            margin-top: 10px;
            margin-left: auto;
            margin-right: 0;
            width: fit-content;
            padding: 5px;
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 17px;
            cursor: pointer;
        }

        &__head {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            #close-icon {
                cursor: pointer;
            }
        }

        p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #272737;
            text-align: center;
            width: 100%;
        }

        &__item {
            margin-top: 20px;
            background: #F1F1F1;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            width: 443px;
            height: 67px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 20px;
            padding-right: 23px;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: 400ms;
            cursor: pointer;

            &:hover {
                background: rgba(63, 115, 255, 0.07);
            }

            div {
                width: 100%;
            }

            #act {
                text-align: right;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #3F73FF;
            }

            p {
                text-align: left;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #272737;
            }

            small {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: #272737;
            }
        }
    }

    &__menu {
        background: #272737;
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 40px;

        &__head {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            #settings-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }

            #scale-logo {
                width: 166px;
                height: 59px;
            }
        }

        .logoutButton{
            color: #272737;
            padding: 1px;
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 13px;
            cursor: pointer;
        }

        #logged-user {
            margin-top: 22px;
            color: white;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
        }
    }

    &__body {
        background: #F1F1F1;
        &__addNewBusinessModal{
            width: 534px;
            padding: 30px;
            flex-direction: column;
            justify-content: baseline;
            align-items: baseline;
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 60px;
            padding-bottom: 60px;
            animation: animateBusinessCreate 1s linear;
            overflow: hidden;

            @keyframes animateBusinessCreate{
                0%{
                    max-height: 0;
                }
                100%{
                    max-height: 1000px;
                }
            }

            .saveBusinessButton{
                margin-top: 10px;
                display: block;
            }

            .closeBusinessButton{
                margin-top: 10px;
                display: block;
            }

            input{
                width: 100%;
                margin-top: 5px;
                outline: none;
                padding: 5px;
            }
            h1{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                color: #272737;
                text-align: center;
                width: 100%;
            }
            #controles{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }
            #search-countries{
                position: relative;
            }
            &__countriesDropdown{
                flex-direction: column;
                background: white;
                position: absolute;
                z-index: 1;
                top: 270px;
                width: 434px;
                padding: 10px;
                height: 425px;
                overflow-y: scroll;
                border: 1px solid gray;
                animation: animateCDD 1s linear;
                @keyframes animateCDD{
                    0%{
                        max-height: 0px;
                    }
                    100%{
                        max-height: 1000px;
                    }
                }
                strong{
                    margin-top: 10px;
                    cursor: pointer;
                    font-family: 'Roboto';
                }
                &::-webkit-scrollbar {
                    width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #272737;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(0, 163, 255);
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(0, 163, 255);
                }
            }
        }
    }
}

@media screen and (max-width: 1390px){
    .route__chooseBusiness__menu{
        padding-left: 30px;
        padding-right: 30px;
    }
    .route__chooseBusiness__body__addNewBusinessModal__countriesDropdown{
        top: 520px;
    }
}

@media screen and (max-width: 1270px){
    body{
        background: #ffff;
    }
    .route__chooseBusiness{
        grid-template-columns: 1fr;
        height: fit-content;
        &__menu{
            height: fit-content;
        padding-bottom: 20px;
        }
        &__body{
            height: 100%;
        }
    }
    .route__chooseBusiness__body{
        height: 100vh;
    }
}

@media screen and (max-width: 543px){
    .route__chooseBusiness{
        &__chooseModal{
            width: 450px;
            margin-left: auto;
            margin-right: auto;
            &__item{
                width: 350px;
            }
        }
    }

    .route__chooseBusiness__body__addNewBusinessModal__countriesDropdown{
        width: 270px;
    }

    .route__chooseBusiness__body__addNewBusinessModal{
        width: 370px;
        margin-left: auto;
        margin-right: auto;
    }

    .route__chooseBusiness__body__addNewBusinessModal input::placeholder{
        font-size: 12px;
    }
}

@media screen and (max-width: 449px){
    .route__chooseBusiness{
        &__chooseModal{
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            padding-left: 0;
            padding-right: 0;
            padding-top: 20px;
            padding-bottom: 0;
            p{
                font-size: 24px;
            }
            &__item{
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                p{
                    font-size: 14px;
                }
            }
        }
    }
}