.component__invoice {
    height: fit-content;
    background: #F5F5F5;
    width: 1000px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    animation: fadeinout 0.5s linear forwards;
    position: relative;

    @keyframes fadeinout {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &__emailModal{
        position: absolute;
        background: rgba(0, 0, 0, 0.274);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: animateEmailModal .5s linear;

        @keyframes animateEmailModal{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }

        &__box{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            width: 350px;
            margin-top: 35%;
            background: white;
            padding: 30px;
            border-radius: 5px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            h2{
                font-family: 'Roboto';
                margin-bottom: 10px;
                font-size: 20px;
            }
            input{
                padding: 3px;
                outline: none;
                border: none;
                border-bottom: 1px solid black;
                width: 100%;
            }

            .sendEmailBtn{
                margin-top: 10px;
                width: 100px;
                height: 2rem;
            }

            .cancelEmailBtn{
                color: black;
            }
        }
    }

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        #close-btn {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #272737;
        }

        &__dropdown {
            width: 300px;
            border-radius: 10px;
            background-color: white;
            position: relative;
        }

        &__dropdown-header {
            padding: 15px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #272737;
        }

        &__dropdown-body {
            padding: 5px;
            border-top: 1px solid #E5E8EC;
            display: none;
        }

        &__dropdown-body.open {
            display: block;
            position: absolute;
            width: fit-content;
            margin-top: 10px;
            background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 20px;
            z-index: 1;
            animation: animateDropdown 0.5s linear;
            overflow: hidden;

            @keyframes animateDropdown {
                0% {
                    max-height: 0;
                }

                100% {
                    max-height: 1000px;
                }
            }
        }

        &__dropdown-item {
            padding: 10px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #272737;
            transition: 400ms;
        }

        &__dropdown-item:hover {
            cursor: pointer;
            color: #00A3FF;
        }

        &__dropdown-item-dot {
            opacity: 0;
            color: #91A5BE;
            transition: all .2s ease-in-out;
        }

        &__dropdown-item-dot.selected {
            opacity: 1;
        }

        .icon {
            font-size: 13px;
            color: #91A5BE;
            transform: rotate(0deg);
            transition: all .2s ease-in-out;
        }

        .icon.open {
            transform: rotate(90deg);
        }
    }

    #msg{
        color: red;
        font-family: 'Roboto';
        text-align: center;
        margin-top: 20px;
        font-weight: 400;
    }

    .invoice__pdf {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        width: 700px;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &__file {
        width: 700px;
        background: white;
        height: fit-content;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        padding: 50px;
        position: relative;

        #cancelled{
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(254, 105, 105, 0.27);
            padding: 3px;
            width: 100%;
            p{
                color: black;
                text-align: center;
                font-family: 'Roboto';
                font-weight: 700;
            }
        }

        #person-responsible{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #272737;
            margin-top: 6px;
        }

        &__head {
            display: grid;
            grid-template-columns: 50% 50%;
            width: 100%;

            div {
                h4 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                    margin-top: 6px;
                }
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: 0;
                width: 166px;
                height: 59px;
            }
        }

        &__client {
            display: grid;
            grid-template-columns: 50% 50%;
            margin-top: 43px;

            &__left {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: baseline;
                align-items: baseline;

                #invoice-customer{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                    padding-bottom: 2px;
                    border-bottom: 1px solid gray;
                    margin-bottom: 2px;
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                }

                #editInvoicePen {
                    position: absolute;
                    top: 0;
                    right: 5%;
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                    box-shadow: 0px 0px 2px black;
                    border-radius: 30%;
                }

                &__editClient {
                    position: absolute;
                    width: fit-content;
                    z-index: 1;
                    animation: animateInvoiceModal 1s linear;
                    display: flex;
                    flex-direction: column;
                    height: 200px;
                    width: 100%;
                    padding: 5px;
                    overflow: hidden;
                    top: 0;

                    &__nav {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 12px;
                            height: 12px;
                            cursor: pointer;
                            margin-left: 5px;
                            display: block;
                            margin-top: 5px;
                        }

                        input {
                            outline: none;
                            margin-top: 5px;
                            animation: animateInvoiceModal 1s linear;
                            width: 100%;
                        }
                    }

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                        margin-top: 0.5rem;
                        cursor: pointer;
                        padding: 3px;

                        &:hover {
                            background: rgba(63, 115, 255, 0.07);
                        }
                    }

                    background: white;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    padding: 5px;

                    &__list {
                        overflow-y: scroll;
                        margin-top: 3px;
                        height: 160px;

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #272737;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: rgb(0, 163, 255);
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: rgb(0, 163, 255);
                        }
                    }
                }
            }

            &__right {
                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        cursor: pointer;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                &__editDueDate {
                    width: 173px;
                    height: fit-content;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                    border-radius: 2px;
                    z-index: 1;
                    overflow: hidden;
                    position: absolute;
                    animation: animateInvoiceModal 1s linear;

                    &__head {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        background: rgba(63, 115, 255, 0.07);

                        img {
                            width: 12px;
                            height: 12px;
                            cursor: pointer;
                        }

                        h5 {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: left;
                            color: #0D99FF;
                        }
                    }

                    &__body {
                        padding: 10px;

                        span {
                            display: flex;
                            flex-direction: row;
                            justify-content: left;
                            align-items: center;
                            margin-bottom: 10px;

                            input {
                                background: #FFFFFF;
                                border: 1px solid #73717C;
                                margin-right: 10px;
                                width: 16px;
                                height: 16px;
                            }

                            p {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: right;
                                color: #272737;
                            }
                        }

                        &__daysInput {
                            display: flex;
                            flex-direction: column;
                            justify-content: baseline;
                            align-items: center;
                            margin-top: 9px;
                            margin-bottom: 9px;
                            margin-left: 32px;

                            p {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: left;
                                color: #272737;
                                margin-bottom: 8px;
                            }

                            input {
                                width: 24px;
                                height: 24px;
                                background: rgba(63, 115, 255, 0.07);
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                border-radius: 2px;
                                border: none;
                                outline: none;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                                color: #272737;
                            }
                        }
                    }
                }
            }
        }

        &__description {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            position: relative;

            #editInvoicePen {
                position: absolute;
                right: 0;
                top: 0;
                width: 14px;
                height: 14px;
                box-shadow: 0px 0px 2px black;
                border-radius: 30%;
                cursor: pointer;
            }

            #e-search-services {
                width: 228px;
                height: 28px;
                background: #FFFFFF;
                box-shadow: 0px 0px 7px gray;
                border-radius: 2px;
                outline: none;
                border: none;
                padding: 3px;
                margin-top: 3px;
                position: absolute;
                left: 0
                
            }

            &__editArticles {
                position: absolute;
                bottom: -70%;
                left: 0;
                position: absolute;
                width: 100%;
                z-index: 1;
                height: fit-content;
                max-height: 300px;
                overflow-y: scroll;
                top: 3rem;
                background: white;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                border-radius: 2px;
                padding: 5px;
                animation: animateInvoiceModal 1s linear;
                cursor: pointer;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #272737;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(0, 163, 255);
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: rgb(0, 163, 255);
                }

                img {
                    width: 12px;
                    height: 12px;
                    cursor: pointer;
                    display: block;
                    margin-left: 0;
                    margin-right: auto;
                }

                #service-block {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    width: 100%;

                    p {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #272737;
                        margin-top: 0.5rem;
                        cursor: pointer;
                        width: 140px;
                        text-align: left;

                        &:nth-child(1) {
                            width: 50px;
                        }
                    }

                    transition: 400ms;
                    padding: 3px;

                    &:hover {
                        background: rgba(63, 115, 255, 0.07);
                    }
                }
            }

            &__addQuantityModal{
                position: absolute;
                width: 50%;
                background: white;
                z-index: 1;
                top: 0;
                left: 25%;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
                animation: animateQuantity 0.2s linear;
                flex-direction: column;
                &__enterValue{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                }

                h1{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 14px;
                    color: #272737;
                }

                input{
                    width: 50px;
                    height: 24px;
                    background: rgba(63, 115, 255, 0.07);
                    border-radius: 2px;
                    border: none;
                    outline: none;
                    font-family: 'Roboto';
                    font-size: 12px;
                    text-align: center;
                    color: #272737;
                }

                .addQuantityButton{
                    margin-top: 15px;
                    width: 50px;
                    height: 30px;
                    display: block;
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            #service-item {
                text-align: center;
                display: flex;
                flex-direction: column;
                width: fit-content;

                &:nth-child(1) {
                    strong {
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }
                }

                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                }

                strong {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                    text-align: center;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 10px;
                        height: 10px;
                        margin-right: 2px;
                        cursor: pointer;
                    }
                }
            }
        }

        &__total {
            display: grid;
            grid-template-columns: 50% 50%;

            #editCalcPen{
                position: absolute;
                right: -10%;
                width: 14px;
                height: 14px;
                cursor: pointer;
                box-shadow: 0px 0px 2px black;
                border-radius: 30%;
            }

            &__table {
                width: 100%;
                position: relative;

                table {
                    width: 100%;
                    text-align: center;

                    td {
                        cursor: pointer;
                        &:nth-child(2) {
                            text-align: right;
                        }

                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: center;
                        color: #272737;
                    }
                }

                &__paidModal{
                    position: absolute;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                    border-radius: 2px;
                    width: fit-content;
                    height: 126px;
                    animation: animateInvoiceModal 1s linear;
                    overflow: hidden;
                    left: 0;
    
                    h5 {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: left;
                        color: #0D99FF;
                        background: rgba(63, 115, 255, 0.07);
                        border-radius: 2px 2px 0px 0px;
                        padding-left: 18px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
    
                    &__body {
                        padding: 10px;
    
                        div {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 19px;
    
                            input {
                                width: 100px;
                                height: 24px;
                                margin-right: auto;
                                margin-left: auto;
                                background: rgba(63, 115, 255, 0.07);
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                border-radius: 2px;
                                border: none;
                                outline: none;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                                color: #272737;
                            }
    
                            .close-btn {
                                color: black !important;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #272737;
                            }
    
                            .add-paid-btn {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #FFFFFF;
                                width: 96px;
                                height: 26px;
                            }
                        }
                    }
                }

                &__avansModal{
                    position: absolute;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                    border-radius: 2px;
                    width: fit-content;
                    height: 126px;
                    animation: animateInvoiceModal 1s linear;
                    overflow: hidden;
                    left: 0;
    
                    h5 {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: left;
                        color: #0D99FF;
                        background: rgba(63, 115, 255, 0.07);
                        border-radius: 2px 2px 0px 0px;
                        padding-left: 18px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
    
                    &__body {
                        padding: 10px;
    
                        div {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 19px;
    
                            input {
                                width: 100px;
                                height: 24px;
                                margin-right: auto;
                                margin-left: auto;
                                background: rgba(63, 115, 255, 0.07);
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                border-radius: 2px;
                                border: none;
                                outline: none;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                                color: #272737;
                            }
    
                            .close-btn {
                                color: black !important;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #272737;
                            }
    
                            .add-paid-btn {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #FFFFFF;
                                width: 96px;
                                height: 26px;
                            }
                        }
                    }
                }

                &__rabatModal{
                    position: absolute;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
                    border-radius: 2px;
                    width: fit-content;
                    height: 126px;
                    animation: animateInvoiceModal 1s linear;
                    overflow: hidden;

                    h5 {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        text-align: left;
                        color: #0D99FF;
                        background: rgba(63, 115, 255, 0.07);
                        border-radius: 2px 2px 0px 0px;
                        padding-left: 18px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }

                    &__body {
                        padding: 10px;

                        div {
                            display: flex;
                            flex-direction: row;
                            width: 100%;
                            align-items: center;
                            justify-content: space-evenly;
                            margin-bottom: 19px;

                            input {
                                width: 50px;
                                height: 24px;
                                background: rgba(63, 115, 255, 0.07);
                                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                                border-radius: 2px;
                                border: none;
                                outline: none;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: center;
                                color: #272737;
                            }


                            p {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 14px;
                                text-align: right;
                                color: #272737;
                            }

                            .close-btn {
                                color: black !important;
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #272737;
                            }

                            .add-rabat-btn {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #FFFFFF;
                                width: 96px;
                                height: 26px;
                            }
                        }
                    }
                }
            }
        }

        &__amountDue {
            display: grid;
            grid-template-columns: 50% 25% 25%;

            div {
                p {
                    text-align: center;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 15px;
                    text-align: center;
                    color: #00A3FF;
                }

                h3 {
                    text-align: right;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    text-align: right;
                    color: #272737;
                }
            }
        }

        &__notes {
            margin-top: 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            h1 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 13px;
                line-height: 15px;
                color: #00A3FF;
            }

            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: #272737;
            }

            div {

                &:nth-child(2) {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }

                &:nth-child(3) {
                    display: block;
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }
        #estimate-number{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 900;
            font-size: 15px;
            line-height: 14px;
            color: #272737;
            margin-top: 10px;
        }
        &__remark{
            min-height: 150px;
            height: fit-content;
            position: relative;
            #editRemarkPen{
                position: absolute;
                right: -5%;
                width: 14px;
                height: 14px;
                cursor: pointer;
                box-shadow: 0px 0px 2px black;
                border-radius: 30%;
            }
            p {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 14px;
                color: #272737;
                margin-top: 10px;
            }

            &__newRemark{
                animation: animateNewRemark .5s linear;
                @keyframes animateNewRemark{
                    0%{
                        opacity: 0;
                    }
                    100%{
                        opacity: 1;
                    }
                }
                width: 100%;
                textarea{
                    width: 100%;
                    height: 100px;
                    outline: none;
                    padding: 5px;
                    border: none;
                }
            }
        }

        &__signature{
            width: 100px;
            margin-bottom: 50px;
            display: block;
            margin-left: auto;
            margin-right: 0;

            p{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #272737;
                margin-top: 10px;
                text-align: center;
            }
        }

    }
}

@media screen and (max-width: 1324px){
    .component__invoice{
        width: 100%;
        &__head{
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: 1179px) {
    .component__invoice {
        width: 700px;
        &__head{
            width: 100%;
        }
    }

    .component__invoice__head {
        position: relative;
    }

    .component__invoice__head__dropdown-body.open {
        top: 50px;
    }
}

@media screen and (max-width: 725px) {
    .component__invoice {
        width: 90%;

        .invoice__pdf {
            width: 100%;

            .component__invoice__file {
                width: 100%;
                padding: 20px;
            }
        }
    }

    .component__invoice__head__dropdown {
        width: 200px;
    }
}

@media screen and (max-width: 656px) {
    .component__invoice__head h1 {
        font-size: 25px;
    }

    .component__invoice__head__dropdown {
        width: 170px;
    }
}

@media screen and (max-width: 579px) {
    .component__invoice__head {
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 1fr;
        align-items: end;

        #invoice-item {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }

        #close-btn {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 558px) {
    .component__invoice__file {
        padding: 20px;
    }

    .component__invoice__file__description div:nth-child(1) {
        width: 120px;
        text-align: left;
    }

    .component__invoice__file__total {
        grid-template-columns: 40% 60%;
    }

    .component__invoice__file__client {
        grid-template-columns: 40% 60%;
    }

    .component__invoice__head h1 {
        font-size: 18px;
    }

    .component__invoice__head__dropdown {
        height: 50px;
    }

    .component__invoice__head__dropdown-header {
        font-size: 14px;
    }

    .component__invoice__head__dropdown {
        height: 40px;
    }

    .component__invoice__head #close-btn {
        width: 16px;
        height: 16px;
    }

    .component__invoice__file__description #service-item strong{
        font-size: 10px;
    }
}

@media screen and (max-width: 498px){
    .component__invoice__file__description #service-item p{
        font-size: 10px;
    }
    .component__invoice__file__description__editArticles #service-block p{
        width: fit-content;
    }
}

@media screen and (max-width: 457px) {
    .component__invoice__file__description div:nth-child(1) {
        width: 80px;
        text-align: left;
    }

    .component__invoice__file__total {
        grid-template-columns: 30% 70%;
    }

    .component__invoice {
        width: 95%;
    }

    .component__invoice .invoice__pdf .component__invoice__file{
        padding: 5px;
    }
}

@media screen and (max-width: 446px) {
    .component__invoice__head__dropdown-body.open {
        top: 70px;
    }
}

@media screen and (max-width: 421px) {
    .component__invoice__file__description div strong {
        font-size: 10px;
    }
}
