.component__memorandum {
    width: 876px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    min-height: 1000px;
    animation: animateNavigate 1s linear;

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .newMemorandumButton {
            width: 200px;
            padding: 5px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #FFFFFF;
        }

        h1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            color: #272737;
        }

        &__controles{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .saveMemorandumButton{
                width: 200px;
                padding: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
                margin-right: 5px;
            }
            .downloadMemorandumButton{
                width: 200px;
                padding: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
                margin-right: 5px;
            }

            .saveEditButton{
                width: 200px;
                padding: 5px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
            }

            #closeMemorandumIcon{
                width: 24px;
                height: 24px;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    #msg{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: white;
        padding: 3px;
        text-align: center;
        width: 100%;
        border-radius: 0px 0px 10px 10px;
        animation: aniMsg 0.5s linear;
        margin-bottom: 20px;
    
        @keyframes aniMsg{
            0%{
                max-height: 0;
            }
            100%{
                max-height: 1000px;
            }
        }
    }

    &__createNewMemorandum {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        width: 595px;
        height: 842px;
        margin-left: auto;
        margin-right: auto;
        padding: 50px;
        background: white;
        animation: animateMemo 0.5s linear;
        overflow: hidden;
        position: relative;

        @keyframes animateMemo {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &__logoModal{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 5%;
            background: white;
            z-index: 1;
            box-shadow: 0 0 4px gray;
            padding: 10px;
            overflow-y: scroll;
            height: 200px;
            animation: animateMemoLogoModal 0.5s linear;

            @keyframes animateMemoLogoModal {
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
            span{
                border: 1px solid #272737;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 5px;
                img{
                    width: 200px;
                    height: 50px;
                }
            }

            p{
                font-family: 'Roboto';
                font-size: 14px;
                color: #272737;
            }

            small{
                font-family: 'Roboto';
                font-size: 10px;
                color: #272737;
            }
        }

        &__heading {
            display: grid;
            grid-template-columns: 50% 50%;
            position: relative;

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
            }


            #newmemopen {
                position: absolute;
                right: 0;
                margin-top: 0;
            }

            div {
                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                    margin-top: 6px;
                }

                h4 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                }
            }
        }

        &__body{
            height: 82%;
            textarea{
                width: 100%;
                height: 100%;
                padding: 10px;
                outline: none;
                border: none;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #272737;
                &::placeholder{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 14px;
                    color: gray;
                    margin-top: 6px;
                }
            }
        }
    }

    &__editMemorandum{
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        width: 595px;
        min-height: 842px;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding: 50px;
        background: white;
        animation: animateMemo 0.5s linear;
        overflow: hidden;

        @keyframes animateMemo {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &__heading {
            display: grid;
            grid-template-columns: 50% 50%;

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            div {
                p {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                    margin-top: 6px;
                }

                h4 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                }
            }
        }

        &__body{
            width: 100%;
            height: fit-content;
            padding: 10px;
            outline: none;
            border: none;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            color: #272737;
            position: relative;

            textarea{
                width: 100%;
                min-height: 500px;
                height: 82%;
                padding: 10px;
                outline: none;
                border: 1px solid gray;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #272737;
                animation: animateEditMemo 1s linear;

                &::placeholder{
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 14px;
                    color: gray;
                    margin-top: 6px;
                }

                @keyframes animateEditMemo{
                    0%{
                        max-height: 0px;
                    }
                    100%{
                        max-height: 1000px;
                    }
                }
            }

            #editMemorandumPen{
                position: absolute;
                right: -10px;
                box-shadow: 0 0 3px black;
                border-radius: 30%;
                cursor: pointer;
                width: 16px;
                height: 16px;
            }

            &__sign{
                width: 100px;
                margin-top: 20rem;
                display: block;
                margin-left: auto;
                margin-right: 0;
                p{
                    text-align: center;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #272737;
                    margin-top: 6px;
                }
            }
        }
    }
}

#table-memorandums-info{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #272737;
    text-align: center;
}

@media screen and(max-width: 901px){
    .component__memorandum{
        width: 95%;
    }
}

@media screen and (max-width: 715px){
    .component__memorandum__head h1{
        font-size: 22px;
    }

    .component__memorandum__head .newMemorandumButton{
        width: 100px;
        font-size: 14px;
        height: 20px;
    }
    .component__memorandum__head__controles .saveMemorandumButton{
        width: 100px;
        font-size: 14px;
        height: 20px;
    }
    .component__memorandum__createNewMemorandum{
        width: 95%;
    }
    .component__memorandum__head__controles .saveEditButton{
        width: 100px;
        font-size: 14px;
        height: 20px;
    }
    .component__memorandum__head__controles .downloadMemorandumButton{
        width: 130px;
        font-size: 14px;
        height: 20px;
    }
    .component__memorandum__head__controles #closeMemorandumIcon{
        width: 16px;
        height: 16px;
    }
}

@media screen and(max-width: 600px){
    #table-memorandums-info{
        font-size: 13px;
        text-align: end;
    }
    .active{
        flex-direction: column;
        justify-content: space-between;
    }
    .component__memorandum__editMemorandum__body #editMemorandumPen{
        top: -20px;
        right: -30px;
    }
}

@media screen and (max-width: 540px){
    .component__memorandum__editMemorandum{
        width: 450px;
    }
}

@media screen and(max-width: 470px){
    #table-memorandums-info{
        font-size: 13px;
        text-align: end;
    }
    .component__memorandum__createNewMemorandum{
        padding: 20px;
    }
    .component__memorandum__editMemorandum{
        padding: 20px;
        width: 380px;
    }
    .component__memorandum__editMemorandum__body #editMemorandumPen{
        right: -10px;
        top: -10px;
    }
}

@media screen and (max-width: 388px){
    .component__memorandum__editMemorandum{
        width: 365px;
    }
}