.component__viewTravelConfirmation{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    width: 1350px;
    margin-left: -27%;
    background: white;
    padding: 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    position: relative;
    margin-bottom: 50px;
    animation: animateOrder .5s linear;

    @keyframes animateOrder{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    &__left{
        &__item{
            font-family: 'Roboto';
            border-bottom: 1px solid black;
            padding-bottom: 10px;
            span{
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-top: 20px;
                #data{
                    border-bottom: 1px dashed black;
                    margin-top: 0;
                    margin-left: 5px;
                    margin-right: 5px;
                    width: 200px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }
            }
            #item-foo{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 40%;
                margin-left: auto;
                margin-right: 0;
                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;
                    small{
                        font-size: 10px;
                    }
                }
            }
        }
    }

    &__right{
        font-family: 'Roboto';
        p{
            #dashed{
                border-bottom: 1px dashed black;
            }
            span{
                border-bottom: 1px solid black;
            }
            margin-bottom: 20px;
        }

        &__table{
            display: grid;
            grid-template-columns: 70% 30%;
            table{
                span{
                    font-weight: 700;
                    height: 25px;
                    padding-left: 5px;
                }
                #row{
                    width: 100%;
                    display: grid;
                    grid-template-columns: 70% 30%;
                    height: 25px;
                    border: 1px solid black;
                    div{
                        border-left: 1px solid black;
                        padding: 3px;
                        padding-left: 5px;
                    }
                }
            }
            #right{
                border: 1px solid black;
                height: 96%;
                margin-top: 2px;
                p{
                    font-size: 14px;
                    margin-left: 5px;
                    margin-top: 5px;
                    margin-bottom: 0;
                }
            }
        }
        #sum{
            border: 1px solid black;
            margin-top: 0;
            padding-left: 10px;
            display: grid;
            grid-template-columns: 69% 31%;
            width: 99.6%;
            margin-right: 0;
            margin-left: auto;
            padding-bottom: 0;
            margin-bottom: 0;
            #p-with-border{
                border-left: 1px solid black;
                border-bottom: none;
                padding-left: 5px;
                margin-bottom: 0;
            }
            p{
                margin-bottom: 0;
            }
        }

        &__footer{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 50px;
            margin-top: 150px;
            div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                #dashed{
                    border-bottom: 1px dashed black;
                    width: 100%;
                }
                small{
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen and (max-width: 1812px){
    .component__viewTravelConfirmation{
        width: 1200px;
        margin-left: -20%;
    }
}

@media screen and (max-width: 1545px){
    .component__viewTravelConfirmation{
        grid-template-columns: 1fr;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 673px){
    .component__viewTravelConfirmation{
       padding: 20px;
    }
    .component__viewTravelConfirmation__left__item span{
        flex-direction: column;
        margin-top: 10px;
    }
    .component__viewTravelConfirmation__left__item p {
        margin-top: 10px;
    }
    .component__viewTravelConfirmation__right__table{
        grid-template-columns: 1fr;
    }
    .component__viewTravelConfirmation__right span{
        flex-direction: column;
    }
    .component__viewTravelConfirmation__left__item #item-foo{
        width: 100%;
    }
    .component__viewTravelConfirmation__right{
        h1{
            font-size: 20px;
        }
    }
}