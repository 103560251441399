.component__remarksModal{
    position: absolute;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    top: -250%;
    left: 25%;
    width: 300px;
    animation: animateRemarksModal 0.2s linear;

    @keyframes animateRemarksModal{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    h1{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 38px;
        color: #272737;
    }
    .addNewRemarkButton{
        font-size: 15px;
        width: 70px;
        height: 30px;
        font-family: 'Roboto';
        font-weight: 700;
        margin-top: 20px;
        display: block;
        margin-left: auto;
        margin-right: 0;
        cursor: pointer;
    }
    .cancelNewRemarkButton{
        font-size: 15px;
        width: 70px;
        height: 30px;
        font-family: 'Roboto';
        font-weight: 700;
        display: block;
        margin-left: 0;
        margin-right: auto;
        color: black;
        margin-bottom: 10px;
        cursor: pointer;
    }
    textarea{
        width: 100%;
        outline: none;
        padding: 5px;
        margin-top: 10px;
        height: 100px;
        font-family: 'Roboto';
    }
}

@media screen and (max-width: 544px){
    .component__remarksModal{
        left: 15%;
    }
}

@media screen and (max-width: 460px){
    .component__remarksModal{
        left: 10%;
    }
}

@media screen and (max-width: 392px){
    .component__remarksModal{
        left: 3%;
    }
}